import { ArrayOptionsMapRes } from "system/helpers/types";
import { TransType } from "system/translations/types";
import { ConfigType } from "./types";

const timeOptions: ArrayOptionsMapRes = Array.from({ length: 24 }, (_, index) => {
  const hour = index.toString().padStart(2, '0');
  return { label: `${hour}:00`, value: `${hour}:00` };
})

const weekdays: string[]  = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const weekdaysOptions: ArrayOptionsMapRes = weekdays.map((day) => ({
  label: day,
  value: day
}));

const config: ConfigType = {
  attributes: [
    {
      label: 'Transaction Amount',
      value: 'transactionAmount'
    },
    // {
    //   label: 'FTD',
    //   value: 'ftd'
    // },
    {
      label: 'Card Network',
      value: 'cardNetwork'
    },
    {
      label: 'Card Funding Type',
      value: 'cardFundingType'
    },
    {
      label: 'Card Category',
      value: 'cardCategory'
    },
    {
      label: 'Card BIN',
      value: 'cardBIN'
    },
    // {
    //   label: 'Card BIN Range',
    //   value: 'cardBINRange'
    // },
    {
      label: 'Issuer Country ',
      value: 'issuerCountry'
    },
    {
      label: 'Login Country',
      value: 'loginCountry'
    },
    {
      label: 'Login IP ',
      value: 'loginIP'
    },
    {
      label: 'Currency',
      value: 'currency'
    },
    {
      label: 'UTC Time',
      value: 'utcTime'
    },
    {
      label: 'Day of week',
      value: 'dayOfWeek'
    },
  ],
  statuses: [
    { label: "Active", value: true },
    { label: "Deactivated", value: false }
  ],
  ruleType: [
    { label: "On display", value: 'onDisplay' },
    { label: "On submit", value: 'onSubmit' },
  ],
  condition: {
    transactionAmount: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal',
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'Greater than',
          value: 'greaterthan'
        },
        {
          label: 'Less than',
          value: 'lessthan'
        },
        {
          label: 'Greater or equal',
          value: 'greaterorequal'
        },
        {
          label: 'Less or equal',
          value: 'lessoorequal'
        },
        {
          label: 'Between',
          value: 'between'
        },
      ],
      valueOptions: [],
      valueComponent: 'input',
      conditionOptionsWithAnotherValueComponent: {
        between: 'twoInputs',
      },
      additionalData: (_t) => ({
        warn: true,
        warnText: _t('specify_amount_in_minor_units')
      }),
    },
    ftd: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal',
        }
      ],
      valueComponent: 'select',
      valueOptions: [
        {
          label: 'Yes',
          value: 'yes'
        },
        {
          label: 'No',
          value: 'no'
        },
      ]
    },
    cardNetwork: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'In',
          value: 'in'
        },
        {
          label: 'Not in',
          value: 'notin'
        }
      ],
      valueComponent: 'multiSelect',
      valueOptions: []
    },
    cardFundingType: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'In',
          value: 'in'
        },
        {
          label: 'Not in',
          value: 'notin'
        }
      ],
      valueOptions: [],
      valueComponent: 'select',
    },
    cardCategory: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'In',
          value: 'in'
        },
        {
          label: 'Not in',
          value: 'notin'
        }
      ],
      valueOptions: [],
      valueComponent: 'multiSelect',
    },
    cardBIN: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'In',
          value: 'in'
        },
        {
          label: 'Not in',
          value: 'notin'
        }
      ],
      valueOptions: [],
      valueComponent: 'input',
      conditionOptionsWithAnotherValueComponent: {
        in: 'twoInputs',
        notin: 'twoInputs',
      }
    },
    cardBINRange: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'In',
          value: 'in'
        },
        {
          label: 'Not in',
          value: 'notin'
        },
        {
          label: 'Between',
          value: 'between'
        }
      ],
      valueOptions: [],
      valueComponent: 'input',
    },
    issuerCountry: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'In',
          value: 'in'
        },
        {
          label: 'Not in',
          value: 'notin'
        }
      ],
      valueOptions: [],
      valueComponent: 'multiSelect',
    },
    loginCountry: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'In',
          value: 'in'
        },
        {
          label: 'Not in',
          value: 'notin'
        }
      ],
      valueOptions: [],
      valueComponent: 'multiSelect',
    },
    currency: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'In',
          value: 'in'
        },
        {
          label: 'Not in',
          value: 'notin'
        }
      ],
      valueOptions: [],
      valueComponent: 'multiSelect',
    },
    loginIP: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'In',
          value: 'in'
        },
        {
          label: 'Not in',
          value: 'notin'
        }
      ],
      valueOptions: [],
      valueComponent: 'input',
    },
    utcTime: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'Between',
          value: 'between'
        },
      ],
      valueOptions: [],
      valueComponent: 'input',
      additionalData: (_t: any) => ({
        placeholder: "HH:MM"
      })
    },
    dayOfWeek: {
      conditionOptions: [
        {
          label: 'Equal',
          value: 'equal'
        },
        {
          label: 'Not equal',
          value: 'notequal'
        },
        {
          label: 'In',
          value: 'in'
        },
        {
          label: 'Not in',
          value: 'notin'
        }
      ],
      valueOptions: weekdaysOptions,
      valueComponent: 'select',
      additionalData: (_t: TransType) => ({
        placeholderText: _t('select_day')
      })
    },

  }
};

export default config;
