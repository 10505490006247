const defaultTranslations = {
  name: "Name",
  email: "Email",
  comment: "Comment",
  ip: "Ip",
  role: "Role",
  active: "Active",
  inactive: "Inactive",
  tfa: "Tfa",
  last_login: "Last login",
  last_ip_session: "Last IP session",
  roles: "Roles",
  title: "Title",
  code2: "Code2",
  code3: "Code3",
  no_data_text: "No data, change request params",
  merchants: "Merchants",
  partner: "Partner",
  currency_balance: "Currency balance",
  amount_in_currency: "Amount in currency",
  rate: "Rate as at today",
  balance_in_euro: "Balance in euro",
  available_balance: "Available balance",
  status: "Status",
  sub: "Sub",
  message: "Message",
  search: "Search",
  submit: "Submit",
  clear_all_filters: "Clear all filters",
  reset: "Reset",
  search_by_name: "Search by name",
  date_range: "Date range",
  user: "User",
  kyc_max_amount: "Kyc max amount",
  max_amount: "Max amount",
  action: "Action",
  date: "Date",
  user_ip: "User ip",
  entity_type: "Entity type",
  action_type: "Action type",
  entity_name: "Entity name",
  apply_filters: "Apply filters",
  summary: "Summary",
  no_date_selected: "No date selected",
  deactivated: "Deactivated",
  deactivateUser: "Deactivate user",
  key: "Key",
  old_password: "Old password",
  new_password: "New password",
  confirm_password: "Confirm password",
  merchant: "Merchant",
  merchant_id: "Merchant id",
  event_type: "Event type",
  record_type: "Record type",
  transaction_status_after: "Transaction status after",
  transaction_status_before: "Transaction status before",
  transaction_incoming_id: "Transaction incoming id",
  transactionUUID: "Transaction UUID",
  payment_gateway_code: "Payment gateway code",
  gateway: "Gateway",
  currency: "Currency",
  custom_uuid: "Custom Uuid",
  deposit_methods: "Deposit methods",
  withdrawal_methods: "Withdrawal methods",
  transaction: "Transaction",
  uuid: "UUID",
  uuids: "UUIDs",
  id: "Id",
  provider: "Provider",
  credential: "Credential",
  mask_sensitive_data: "Mask sensitive data",
  method: "Method",
  create: "Create",
  merchant_monitoring_settings: "Merchant monitoring settings",
  edit: "Edit",
  delete: "Delete",
  deactivate: "Deactivate",
  alerts: "Alerts",
  amount: "Amount",
  processed_amount: "Processed amount",
  type: "Type",
  transactions: "Transactions",
  change_transactions_status: "Change transaction status",
  request_history: "Request history",
  users: "Users",
  merchants_balance: "Merchants balance",
  merchants_balance_v2: "Merchants balance 2.0",
  balance: "Balance",
  merchants_fees: "Merchants' fees",
  settlement_details: "Settlement Details",
  balance_history: "Balance History",
  statistics: "Statistics",
  action_log: "Action log",
  history: "History",
  app_settings: "App settings",
  translations: "Translations",
  monitoring_settings: "Monitoring settings",
  pending_transactions_monitoring: "Pending transactions monitoring",
  deposits_percentage: "Pending deposits Percentage",
  check_deposits_older_than: "Check deposits older than",
  check_deposits_to: "Check deposits to",
  deposits_checking_enabled: "Deposits checking enabled",
  withdrawals_checking_enabled: "Withdrawals checking enabled",
  check_withdrawals_older_than: "Check withdrawals older than",
  check_withdrawals_to: "Check withdrawals to",
  withdrawals_percentage: "Pending withdrawals percentage",
  user_management: "User Management",
  monitoring_rules: "Monitoring rules",
  monitoring_merchant: "Monitoring merhcant",
  create_user: "Create new user",
  change_password: "Change password",
  create_merchant: "Create new merchant",
  create_payment_method: "Create payment method",
  edit_user: "Edit user",
  import: "Import",
  export_CSV: "Export CSV",
  cancel: "Cancel",
  on_ok: "Ok",
  resend_ipn: "Resend ipn",
  password: "Password",
  save_changes: "Save changes",
  invalid_email: "Invalid email",
  required: "Required",
  invalid: "Invalid",
  required_email: "Email required",
  deactivateTranslation: "Deactivate translations",
  edit_translation: "Edit translation",
  create_translation: "Create translation",
  description: "Description",
  priority: "Priority",
  edit_role: "Edit role",
  deactivate_role: "Deactivate role",
  "language{fieldName}": "{fieldName}",
  payment_gateways: "Payment gateways",
  payment_gateway: "Payment gateway",
  success_rate_threshold: "Success rate threshold",
  success_rate_check_length: "Success rate check length",
  errors_in_row_threshold: "Errors in row threshold",
  errors_in_row_check_enabled: "Errors in row check enabled",
  success_rate_check_enabled: "Success rate check enabled",
  max_cumulative_amount: "Max cumulative amount",
  max_cumulative_amount_check_enabled: "Max cumulative amount check enabled",
  max_single_amount_check_enabled: "Max single amount check enabled",
  cumulative_amount_check_time_frame: "Cumulative amount check time frame",
  cumulative_amount_check_length: "Cumulative amount check length",
  max_single_amount: "Max single amount",
  currencies: "Currencies",
  gateway_name: "Gateway",
  gateway_transaction_id: "Gateway transactionId",
  ipn: "Ipn",
  payment_methods: "Payment methods",
  countries_supported: "Countries supported",
  operation: "Operation",
  operation_types: "Operation types",
  transaction_statuses: "Transaction statuses",
  transaction_types: "Transaction types",
  export_fields: "Export fields",
  updated_date: "Updated",
  created_date: "Created",
  payment_method: "Payment method",
  reference_number: "Reference number",
  client: "Client",
  country: "Country",
  converted_amount: "Converted amount",
  credit_card: "Credit card",
  merchant_client_id: "Merchant client id",
  card_number: "Card number",
  customer_email: "Customer email",
  customer: "Customer",
  merchant_order_no: "Merchant order No",
  psp_order_no: "PSP order No",
  history_log: "History log",
  state: "State",
  city: "City",
  postcode: "Postcode",
  address: "Address",
  street: "Street",
  wallet_id: "WalletId",
  com_type: "ComType",
  "3d_status": "3d_status",
  mcc: "Mcc",
  descriptor: "Descriptor",
  ipns: "Ipns",
  partial_refund: "Partial refund",
  partial_capture: "Partial capture",
  all_countries: "All countries",
  default: "Default",
  refundable: "Refundable",
  stored_supported: "Stored supported",
  voucher_auto: "Voucher auto",
  dynamic_descriptor: "Dynamic descriptor",
  avs_check: "AvsCheck",
  disable: "Disable",
  api_v2: "API V2",
  allow_dynamic_descriptor: "Allow dynamic descriptor",
  add_sub_merchant: "Add sub merchant",
  sub_merchant: "Sub merchants",
  deposit: "Deposit",
  withdrawal: "Withdrawal",
  payment_type: "Types",
  transaction_type: "Transaction type",
  payment_provider: "Payment provider",
  payment_credentials: "Payment credentials",
  update: "Update",
  update_merchant_payment: "Update merchant payment",
  create_merchant_blacklist: "Create blacklist",
  create_merchant_whitelist: "Create whitelist",
  create_setting: "Create setting",
  update_blacklist_email: "Update blacklist email",
  update_whitelist: "Update whitelist",
  create_role: "Create role",
  create_new_role: "Create new role",
  ip_whitelisting: "IP whitelisting",
  blacklist: "Blacklist",
  whitelist: "Whitelist",
  credentials: "Credentials",
  createdDate: "Created date",
  apiSecret: "Api secret",
  updatedDate: "Updated date",
  apiKey: "Api key",
  settings: "Settings",
  set_up_via_google_auth: "Set up via google authentication",
  go_to_login: "Go to login page",
  your_email_verified: "Your email has been verified",
  you_can_login: "You can now login to your account",
  enter_key_manually: "or enter key manually using application",
  open_menu: "Open menu",
  api_documentation: "API Documentation",
  checkout_integration: "Checkout Integration",
  are_you_sure: "Are you sure?",
  "merchant_details_for_{merchant}": "Merchant details for {merchant}",
  "{form_name}": "{form_name}",
  transaction_details: "Transaction details",
  remember_me: "Remember me",
  continue: "Continue",
  forgot_password: "Forgot password",
  log_in_to: "Log in to",
  all_rights_reserved: "All rights reserved",
  forgot_password_help_text:
    "Enter your account email and we'll send you an email to reset the password",
  send_instructions: "Send instructions",
  success: "Success",
  export_csv_notification: "The report was sent to the email",
  forgot_password_email_sent:
    "You will receive an email with instructions about how to reset your password",
  user_not_confirmed: "User not confirmed, please check your email",
  reset_password: "Reset password",
  other_details: "Other details",
  reset_password_help_text: "Enter a new password for your account",
  back_to_login: "Back to login",
  new_password_placeholder: "Enter new password",
  confirm_new_password: "Confirm new password",
  password_must_be_longer: "Password must be longer",
  password_must_be_shorter: "Password must be shorter",
  password_must_contain_at_least_one_number:
    "Password must contain at least one number",
  password_must_contain_at_least_one_uppercase:
    "Password must contain at least one uppercase letter",
  password_must_contain_at_least_one_special_character:
    "Password must contain at least one special character (#?!@$%^&*-)",
  password_must_contain_at_least_one_lowercase:
    "Password must contain at least one lowercase letter",
  invalid_password_format: "Invalid password format!",
  passwords_must_match: "Passwords must match",
  password_changed: "Password changed",
  password_changed_message:
    "Your password was successfully changed. Your can now use your new password to log into your account.",
  loading: "Loading...",
  code: "Code",
  enter_code: "Enter code",
  verify: "Verify",
  tfa_authentication: "2FA Authentication",
  tfa_authentication_help_text:
    "Enter the code displayed in your Google Authenticator app",
  must_be_a_number: "Must be a number",
  "verify_code_require_{length}_digit": "Code should be {length}-digit",
  "tfa_contact_support_via_mail_{link}":
    "If you’ve lose your device or can’t use your app, please contact {link}",
  verify_qr_title: "Set up via Google Authenticator",
  verify_qr_step_1:
    "1. Download and install the Google Authentificator app on your mobile device.",
  verify_qr_step_2: "2. Scan the QR code",
  verify_qr_step_2_extra: "or enter key manually using the application:",
  verify_qr_step_3:
    "3. Enter the  6-digit code generated by Google Authentificator.",
  request_header: "Request header",
  request_params: "Request params",
  request_data: "Request data",
  response_header: "Response header",
  response_data: "Response data",
  auth_tfa_description:
    "Protect account by requiring an additional layar of security to log in",
  reset_tfa: "Reset Two-factor Authentication",
  auth_tfa: "Two-factor Authentication",
  email_confirmation: "Email confirmation",
  email_confirmation_description:
    "The confirmation link will be sent to the email address registered to this account",
  reset_password_description:
    "User will receive an email with instructions about how to reset the password",
  send_email_link: "Send email with a reset link",
  resend_confirmation_link: "Resend confirmation link",
  selectedCredentials: "selectedCredentials",
  selectedCurrencies: "selectedCurrencies",
  selectedPaymentMethods: "selectedPaymentMethods",
  paymentMethods: "paymentMethods",
  credentialType: "credentialType",
  recipient: "Recipient",
  alert_type: "Alert type",
  menu: "Menu",
  logout: "Logout",
  receive_details: "Receive details",
  copiedUUID: "UUID copied!",
  cumulative_amount: "Cumulative amount",
  errors_in_a_row: "Errors in a row",
  single_amount: "Single amount",
  success_rate: "Success rate",
  select_all: "Select All",
  missed_merchant: "No merchant has been selected",
  remove: "Remove",
  selected_small: "selected",
  value: "Value",
  "deactivating_of_{user}_header_text": "Deactivate {user} account?",
  "deactivating_of_{method}_header_text": "Do you want to deactivate {method} method?",
  deactivating_of_user_body_text:
    "After deactivation, user will lose access to the account.\nBut you can reactivate it at any time.",
  "last_updated_{time}_at": "Last updated {time} ago",
  deactivating_of_method_body_text: 
    "Please be aware that merchants will not be able to use this method until it’s activated back.\nBut you can reactivate it at any time.",
  filters: "Filters",
  duplicate: "Duplicate",
  record_name: "Record name",
  deposits_movements: "Deposits Movements",
  withdrawals_movements: "Withdrawals Movements",
  total_fees: "Total fees",
  movements_ajustment: "Movements ajustment",
  redirect_type: "Redirect type",
  device_data: "Device data",
  transfer_to_merchant: "Transfer to merchant",
  total_balance: "Total balance",
  summary_information: "Summary information",
  to_settle: "To settle",
  on_hold: "On hold",
  roling_reserve: "Rolling reserve",
  "{merchant}_{balance}_{currency}": "{merchant}: {balance} {currency}",
  create_manual_transaction: "Create a Manual transaction",
  manual_transaction: "Manual Transaction",
  movements_ajustment_comment: "Comment for Movements ajustment",
  received_from_merchant: "Received from Merchant",
  transfer_method: "Transfer method",
  rolling_reserve: "Rolling reserve",
  return_of_RR: "Return of RR",
  return_on_hold_amount: "Return on hold amount",
  rolling_reserve_rate: "RR rate",
  rolling_reserve_return_date: "Date of RR return",
  hold_amoun_return_date: "Date of return hold amount",
  fees_for_transfer_to_merchant: "Fees for transfer to merchant",
  fee: "Fee",
  added_fee: "Added fee",
  fixed_fee: "Fixed fee",
  transaction_list: "Transaction list",
  transfer_fee: "Transfer fee",
  created_by: "Created by",
  for_date: "For date",
  search_by_merchant: "Search by Merchant name",
  search_by_psp: "Search by Payment provider name",
  psp_to_merchant: "PSP to Merchant",
  merchants_fees_subtitle: "Set up deposit fees, withdrawals fees, rolling reserve and more for each Merchants and his payment service providers (PSPs).",
  deposit_fees: "Deposit Fees",
  withdrawal_fees: "Withdrawal Fees",
  merhcant_transfers: "Merchant transfers",
  settlement_fees: "Settlements Fees",
  transaction_fees: "Transaction Fees",
  rr_for_deposits: "RR for Deposits",
  number_of_days: "Number of days",
  "{title_name}": "{title_name}",
  deposit_added_fees: "Deposit added Fees",
  withdrawal_added_fees: "Withdrawal added Fees",
  deposit_fixed_fee: "Deposit fixed Fee",
  withdrawal_fixed_fee: "Withdrawal fixed Fee",
  create_merchants_fees: "Create Merchants' fee",
  create_new_merchant_fee: "Add fees",
  total: "Total",
  transaction_currency: "Transaction currency",
  displayed_information: "Displayed information",
  domain: "Domain",
  analytics: "Analytics",
  total_volume: "Total volume",
  total_payments: "Total payments",
  total_withdrawal_volume: "Total withdrawal volume",
  "{period}": "{period}",
  convert_all: 'Convert all into EUR',
  withdrawals: "Withdrawals",
  error_text: "Unfortunatelly your request was not processed. Please try again",
  post: "POST",
  get: "GET",
  "{item}_copied": "{item}_copied",
  add_comment: "Add a comment",
  set_new_value: "Set a new value",
  edit_history: "Edit history",
  author: "Author",
  values_before_change: "Values before change",
  values_after_change: "Values after change",
  opening_available_balance: "Opening available balance",
  opening_total_balance: "Opening total balance",
  movement: "Movement",
  close_period: "Close period",
  adjustment_type: "Adjustment type",
  select_adjustment_type: "Select adjustment type...",
  psp: "PSP",
  select_psp: "Select PSP...",
  select_date: "Select date...",
  payment_providers: "Payment Providers",
  valid_from: "Valid from",
  deposit_transactional_fees: "Deposit transactional Fees, fixed",
  withdrawal_transactional_fees: "Withdrawal transactional Fees, fixed",
  group_by: "Group by:",
  merchant_configuration: "Merchant configuration",
  merchant_configuration_details: "Merchant configuration details",
  merchant_configuration_subtitle: "Set up Rolling Reserve, On Hold, Return formulas for each Merchant and his payment providers",
  set_edit_value: "Set/edit values",
  additional_info: "Additional info",
  'payment_method_{item}': "Payment_method: {item}",
  are_you_sure_to_close_period: "Are you sure you want to close the period for ",
  action_cannot_be_updone: "This action cannot be undone.",
  'close_{period}': "Close {period}",
  not_configuret_payments_providers: "Please be aware that the following Payment Providers has absent or wrong configuration: ",
  not_included_in_calculation: "Please recheck it to have accurate calculation.",
  'open_periods_{amount}': 'You have ({amount}) open period(s):',
  period_to_close: "Periiod to close: ",
  from: "from:",
  to: 'to:',
  opened: "Opened",
  closed: "Closed",
  period_from: "Period from:",
  advanced_settings: "Advanced selection",
  values_name: "Values name",
  configuration_type: "Configuration type",
  select_configuration_type: "Select configuration type...",
  rolling_reserve_strategy: 'Rolling reserve strategy',
  return_rolling_reserve_strategy: 'Return of Rolling reserve strategy',
  onhold_strategy: 'On Hold strategy',
  return_onhold_strategy: 'Return of On Hold strategy',
  select_rolling_reserve_strategy : "Select rolling reserve strategy...",
  select_return_rolling_reserve_strategy : "Select return of rolling reserve strategy...",
  select_onhold_strategy : "Select on hold strategy...",
  select_return_onhold_strategy : "Select return of on hold strategy...",
  select_type : "Select type...",
  select_period_start : "Select period start...",
  period_in_days: 'Period, in days',
  percentage: "Percentage, %",
  onhold_formula: "On Hold calculation formula:",
  formula: "Deposit movements - Rolling Reserve + Return of RR - Deposit fees - Withdrawals - Withdrawals fees - Settlement fees +- Movement Adjustments",
  comment_name: "Comment/name",
  apply_to: "Apply to",
  calculation_time: "Calculation time",
  rate_application: "Rate Application",
  select_rate_application: "Select rate application...",
  correlation: "Correlation",
  period: "Period",
  rate_: "Rate",
  select_currency: "Select currency...",
  select_period: "Select period...",
  start_period: "Start period",
  end_period: "End period",
  create_configuration: "Create configuration",
  add_configuration: "Add configuration",
  manual_rates: "Manual rates",
  set_a_new_manual_rate: "Set a new manual rate",
  view_all_rates: "View all manual rates",
  exchange_rate_config: "Exchange rate configuration",
  other_fees_config: "Other fees configuration",
  on_hold_config: "On hold configuration",
  rolling_reserve_config: "Rolling reserve configuration",
  depositFee: "Deposit fees",
  depositFixedFee: "Deposit fixed fees",
  depositAddedFee: "Deposit added fees",
  withdrawalFee: "Withdrawal fees",
  withdrawalAddedFee: "Withdrawal added fees",
  withdrawalFixedFee: "Withdrawal fixed fees",
  settlementFee: "Settlement fees",
  settlement_fees_percentage: "Settlements Fees, %",
  settlement_fees_fixed: "Settlements fixed fees",
  calculation_currency: "Calculation currency",
  calculation_currency_on: "Calculation currency - Active",
  calculation_currency_off: "Calculation currency - Deactivated",
  transaction_date: "Transaction date",
  number_of_transactions: "Number of transactions",
  daily_amount: "Daily amount",
  warning: "Warning",
  save: "Save",
  "merchant_configuration_exchange_warning_{lostFields}": "Please specify the following fields:\n( {lostFields} ) to save manual rate or click Save to proceed without manual rate setup.",
  back_to_edit: "Back to edit",
  previously_unclosed_periods: 'You can\'t close a period without closing the previous one. Previously unclosed periods: ',
  promt_to_close_previous_period: 'To close the previous period, please change the filtering parameters.',
  get_previous_periods: 'Get previous periods',
  fee_settings: 'Fee settings:',
  configuration_settings: 'Configuration settings:',
  less_than_movement_error: "The value should be less than 0 since you are changing the decrease in movement",
  more_than_movement_error: "The value should be more than 0 since you are changing the increase in movement",
  can_not_be_empty: "This field can not be empty",
  "amount_from_{valueFrom}_to_{valueTo}": 'Amount from: <strong>{valueFrom}</strong> to: <strong>{valueTo}</strong>',
  "comment_from_{valueFrom}_to_{valueTo}": 'Comment from: <strong>{valueFrom}</strong> to: <strong>{valueTo}</strong>',
  no_changes_to_save_try_again: 'You don\'t have any changes to save. Please make changes and try again',
  do_you_want_make_changes_in: "Do You want make changes in",
  be_careful: "Be careful",
  conversion_can_be_set_once: "The conversion can only be set once",
  file_downloaded: "File downloaded successfully",
  "download_{fileName}": "Download {fileName}",
  file_uploader_formats: "You can upload 1 file in format .csv, .xls, .png, .jpeg. File can be up to 1 MB.",
  file_uploader_error_size_limit: "Please upload a file up to 1mb",
  file_uploader_error_format_limit: "Please upload a file in format .csv, .xls, .png, .jpeg",
  file_uploader_error_format_and_size_limit: "Please upload a file in format .csv, .xls, .png, .jpeg and file can be up to 1 MB",
  all: "ALL",
  do_you_want_make_report_with_device_data: "Do You want to get report with Device Data ?",
  include_device_data_to_export_report: "Include device data to the export report",
  push_deposit_ipn_url: 'Push Deposit Url',
  use_separate_url_for_v1_api: 'Use separate Url for V1 api',
  v1_ipn_url: 'V1 Ipn Url',
  v2_ipn_url: 'V2 Ipn Url',
  use_separate_url_for_v2_deposit_and_withdrawal: 'Use separate Url for Deposit and Withdrawal',
  deposit_ipn_url: "Deposit Ipn Url",
  withdrawal_ipn_url: "Withdrawal Ipn Url",
  use_push_deposit_ipn_url: "Use Push Deposit Ipn Url",
  show_all_gateways: "Show all providers",
  payment_orchestration: "Payment Orchestration",
  coverage_report: "Coverage Report",
  provider_constraints: "Provider Constraints",
  routing_rules: "Routing Rules",
  rule_set_name: "Rule set name",
  rule_type: "Rule type",
  create_rule_set: "Create rule set",
  duplicate_rule: "Duplicate rule",
  select_rule_type: "Select Rule Type",
  select_merchant: "Select Merchant",
  select_method: "Select Method",
  strategy: 'Strategy',
  condition: 'Attribute Condition',
  conditionGroup: 'Attribute Condition Group',
  attribute: 'Attribute',
  conditions: 'Condition',
  select_criteria: "Select Criteria",
  select_conditions: "Select Condition",
  strategy_type: "Strategy Type",
  direct: "Direct",
  weight: "Weight",
  weight_value: "Weight value",
  add_attribute: "Add Attribute",
  add_strategy: "Add Strategy",
  rule_set_parametrs: "Rule set parametrs",
  red_line_represents_green_line_represents: "Red line represents the strategy, green line - attribute",
  keep_editing: "Keep editing",
  save_and_close: "Save and close",
  do_you_want_save_changes_before_closing : "Do you want to save the changes before closing",
  changes_you_made_will_not_be_saved : "Changes you made so far will not be saved",
  clear_values: "Clear values",
  create_a_new_rule_set: "Create a new Rule set",
  delete_rule_set: "Delete rule set",
  rule_set_configuration: "Rule set configuration",
  add_condition: "Add Condition",
  add_group: "Add Group",
  "warning_not_created_default_stategy_for": "You don't have a configured default strategy for ",
  promt_to_save_rule_without_default_startegy: 'To save rule, please configure default strategy and try again.',
  "deactivating_of_{rule}_header_text": "Do you want to deactivate {rule}?",
  deactivating_of_rule_body_text: 
    "Please be aware that orchestration rule will not be able until it’s activated back.\nBut you can reactivate it at any time.",
  value_must_be_greater_than_or_equal_0 :'Value must be greater than or equal to 0',
  value_must_be_6_digit_number: 'Value must be an 6-digit number',
  value_must_be_integer: 'Value must be an integer without any decimal point',
  orchestration_history_log: "Orchestration history",
  "duplicate_{ruleName}_for_{merchantName}_{paymentMethodName}": "You want to duplicate the rule <strong>{ruleName}</strong> created for<br><strong>( {merchantName} - {paymentMethodName} ) Pair</strong><br><br>Be careful that the created copy will have all the same parameters, but <strong>only one rule can be active</strong> since for one ( Merchant - Payment Method ) Pair.",
  "delete_{ruleName}_for_{merchantName}_{paymentMethodName}": "You want to delete the rule <strong>{ruleName}</strong> created for<br><strong>( {merchantName} - {paymentMethodName} ) Pair</strong><br><br>Be careful this action can't be undone.",
  you_cannot_create_rule_without_selected_merchant: "You can't keep creating Rule set parametrs without selecting merchant",
  select_merchant_and_try_again: "Please select merchant and keep setting Rule set parametrs.",
  "change_{merchantFrom}_to_{merchantTo}_warning": "You want to change Merchant: from <strong>{merchantFrom}</strong> to <strong>{merchantTo}</strong><br><br>Be careful this action will reset all payment provider values in all strategy settings",
  template_type: "Template type",
  select_template_type: "Select template type",
  select_day: "Select day",
  specify_amount_in_minor_units: "Specify the amount in minor units, e.g. 100 = 1 dollar",
  time_must_be_in_format_HH_MM: "Time must be in HH:MM format and be between 00:00 - 23:59",
  fill_all_fields_with_time: "Fill all fields with time",  
  first_time_must_be_earliert_than_second: "First time must be earliert than second time",
  first_amount_must_be_less_than_second: "First amount must be less than second",
  apply_to_all: "Apply to all",
  apply_to_all_orchestrator_tooltip_text_1: 'Click to synchronize the configurations of the token and new instrument strategies.',
  apply_to_all_orchestrator_tooltip_text_2: 'The data will be taken from the currently activated strategy and applied to the other.',
  are_you_sure_apply_changes_to_all_strategies : "Are you sure you want to apply the changes to both the token and new instrument strategies?",
  this_action_will_replicate_current_settings_to_another : "This action will replicate the current settings from the selected strategy to the other, ensuring both strategies are synchronized with the same configurations.",
  you_have_modified_data_one_of_the_strategies: "You have modified data in one of the strategies",
  warning_apply_to_all_option_has_been_unchecked: 'The "Apply to All" option has been unchecked to prevent unintended synchronization. To apply these changes to both the token and new instrument strategies, please recheck the "Apply to All" button.',
  payment_instrument_management: "Payment Instrument Management",
  customer_id: "Customer Id",
  identification_key: "Payment Instrument ID",
  do_you_want_to_deactivate_mandate: "Do you want to deactivate the customer's mandate?",
  do_you_want_to_remove_mandate: "Do you want to remove the customer's mandate?",
  request_to_change_status_sent: "Request to change status sent",
  billing_name: "Billing name",
  ipn_sent: "Ipn sent",
  status_reasons: "Status reasons",
  status_connection: "Status connection",
  is_cascading_allowed: "Is cascading allowed",
  create_status_reason: "Create status reason",
  status_reason_code: "Status reason code",
  status_reason_title: "Status reason title",
  status_reason_description: "Status reason description",
  withdrawal_status_reasons: "Withdrawal status reasons",
  deposit_status_reasons: "Deposit status reasons",
  create_status_reason_connection: "Create status reason connection",
  code_priority: "Code priority",
  error_value: "Error value",
  sequence_number: "Sequence number",
  status_reason: "Status reason",
  select_status: "Select status...",
  set_code_priority: "Set code priority...",
  set_error_value: "Set error value...",
  error_detail_key: "Error detail key",
  set_error_detail_key: "Set error detail key...",
  set_sequence_number: "Set sequence number...",
  "status_connection_not_filled_fields_warning_{lostFields}": "Please specify the following fields:\n( <strong> {lostFields}  </strong>) to save status configurations or to add new configuration settings.",
  to_save_status_connection_add_configuration_settings: "To save the status connection, you need to add status configuration settings.",
};

export default defaultTranslations;
