import React from "react";
import { TextInput as CarbonTextInput } from "carbon-components-react";
import { Calendar16, Close20 } from "@carbon/icons-react";
import { InputProps } from "components/atoms/Input/types";

const InputWrap = ({ hasWrap, children }: any) =>
  hasWrap ? (
    <div className={"field-with-remove"}>{children}</div>
  ) : (
    <>{children}</>
  );

const Input: React.FC<InputProps> = ({
  id = "input",
  labelText,
  value,
  onChange,
  hideLabel = false,
  style = {},
  placeholder,
  size = "xl",
  onRemove,
  autoFocus = false,
  isCalendar = false,
  invalid = false,
  invalidText,
  ...rest
}) => {
  return (
    <InputWrap hasWrap={!!onRemove}>
      <CarbonTextInput
        autoFocus={autoFocus}
        placeholder={placeholder}
        style={style}
        invalid={invalid}
        {...rest}
        id={id}
        onChange={(e) => {
          onChange(e?.target?.value)
        }}
        value={value}
        labelText={labelText}
        size={size}
        hideLabel={hideLabel}
        autoComplete={isCalendar ? 'off': 'on'}
        invalidText={invalidText}
      />
      {onRemove && (
        <div className={"field-with-remove__btn"} onClick={onRemove}>
          <Close20 />
        </div>
      )}
      {isCalendar && !invalid && <div className={"field-with-calendar-icon"}><Calendar16/></div> }
    </InputWrap>
  );
};

export default Input;
