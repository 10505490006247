import { Delete16 } from "@carbon/icons-react"
import classNames from "classnames"
import { OrchestrationFormConfigCallBack } from "modules/routingRules/types"
import { createEditAttributeValidation } from "modules/routingRules/validation"
import { useTrans } from "system/translations/hooks"
import ChangeValueDialogWindow from "../ChangeValueDialogWindow"

const PaymentOrchestrationTreeViewItem: React.FC<{
  title?: string,
  isDeleted?: boolean,
  parametrs?: any,
  data?: any
  isEdit?: boolean,
  isAuthType?: boolean,
  type: string,
  onRemove?: any,
  onUpdate?: any 
  onClearValues?: any ,
  getOrchestartionFormConfig: OrchestrationFormConfigCallBack
}> = ({
  title = 'Attribute 1: Auth type',
  isDeleted = true,
  parametrs = "",
  data = [],
  isEdit = true,
  isAuthType = false,
  type,
  onRemove,
  onUpdate,
  onClearValues,
  getOrchestartionFormConfig
}) => {
  const { _t } = useTrans()
  const formConfig = getOrchestartionFormConfig(type, data)
  const validation = createEditAttributeValidation(_t, type)
  
  return (
    <div className={classNames("tree-view-item", type === 'authType' && "tree-view-item-authType")}>
      <div className="tree-view-item__top">
      {title}
      {isDeleted && <Delete16  className="tree-view-item__top-remove-btn" onClick={onRemove}/>}
      </div>
      <div className="tree-view-item__bottom">
      {isAuthType
      ? (
        <div className="tree-view-item__bottom-text">
          {parametrs}
        </div>
      )
      : (
        <ChangeValueDialogWindow 
            formConfig={formConfig}
            text={parametrs}
            initialValues={data}
            onUpdate={onUpdate}
            type={type}
            isEdit={isEdit}
            onClearValues={onClearValues}
            validation={validation}
          />
      )
      }
      </div>
    </div>
  )
}

export default PaymentOrchestrationTreeViewItem