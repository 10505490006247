import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { TableColumnType } from "modules/table/types";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("transactionUUID"),
        width: 250,
        id: "transactionUUID",
        accessor: "transactionUUID",
        mobileVisible: true,
      },
      {
        Header: _t('payment_gateway'),
        accessor: "pgwCode",
        id: "pgwCode",
        width: 200,
        mobileVisible: true,
      },
      {
        Header: _t("updated_date"),
        accessor: "updatedDate",
        id: "updatedDate",
        mobileVisible: true,
      },
      {
        Header: _t("created_date"),
        accessor: "createdDate",
        id: "createdDate",
        mobileVisible: true,
      },
    ],
    []
  );

  return columns;
};
