import classNames from "classnames";
import React from "react";

const FieldsArray = ({
  name,
  children,
  onFormChange,
  values = [],
  topTitle = "Recipients",
  addTittle = "Add email address",
  isAddButtonBottom = false,
  maxAmount,
  onAddNewItem,
  className,
}: any) => {
  const add = () => {
    const newVal = [...values];
    onAddNewItem ? onAddNewItem(newVal) : newVal.push("");
    onFormChange(name, newVal);
  };
  return (
    <div className={classNames("fields-array",className && className )}>
      {!isAddButtonBottom &&
        (
          <div className={"fields-array__top"}>
            <div className={"fields-array__top__title"}>
              {topTitle} ({values.length})
            </div>
            <div className={"fields-array__top__divider"} />
            <div className={"fields-array__top__add"} onClick={add}>
              {addTittle} +
            </div>
          </div>
        )
      }
      {children}
      {((maxAmount && values.length < maxAmount) || !maxAmount) && isAddButtonBottom && (<div className={"fields-array__top__add"} onClick={add}>
        {addTittle} +
      </div>)
      }
    </div>
  );
};

export default FieldsArray;
