import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { useGetPaymentGateways } from "modules/merchantsBalanceV2/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { PropsForFormConfig, PropsForFormConfigAdvanced, UseModuleDataCreateRes, UseModuleDataRes } from "./types";
import { useGetSetting } from "modules/settings/apiHooks";
import { useTrans } from "system/translations/hooks";
import { useMemo, useState } from "react";
import { Checkmark16, ChevronDown16, ChevronUp16, Save16 } from "@carbon/icons-react";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { FormConfigType } from "modules/formBuilder/types";
import { useModal } from "modules/modal/hooks";
import { useGetPaymentGatewaysByMerchant } from "modules/merchantConfiguration/apiHooks";

export const useModuleData = (): UseModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGateways = [] } = useGetPaymentGateways();
  const { data: currencies = [] } = useGetSetting('currencies');

  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  
  const currenciesOptions = arrayOptionsMap(currencies, {
    labelKey: "codeLiteral",
    valueKey: "codeLiteral",
  });

  return { merchantsOptions, paymentGatewaysOptions, currenciesOptions };
};

export const useModuleDataCreate = (merchantId: number): UseModuleDataCreateRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGatewaysByMerchant = [] } = useGetPaymentGatewaysByMerchant(merchantId);
  const { data: paymentGatewaysAll = [] } = useGetPaymentGateways();
  const { data: currencies = [] } = useGetSetting('currencies');

  const paymentGatewaysOptionsByMerchant = arrayOptionsMap(paymentGatewaysByMerchant, {
    labelKey: "value",
    valueKey: "label",
  });

  const paymentGatewaysOptionsAll = arrayOptionsMap(paymentGatewaysAll, {
    labelKey: "label",
    valueKey: "value",
  });

  const merchantsOptions = merchantsList.map((item) => ({
    label: item.name,
    value: item,
  }));

  const currenciesOptions = arrayOptionsMap(currencies, {
    labelKey: "codeLiteral",
    valueKey: "codeLiteral",
  });

  return {
    merchantsOptions,
    paymentGatewaysOptionsByMerchant,
    paymentGatewaysOptionsAll,
    currenciesOptions
  };
};

export const useReorderColumns = (columns: any, setSort: any) => {
  const { _t } = useTrans()
  const storagedData = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('columnOrder'))));
  const merchantIdx = !storagedData['merchants-fees']
    ? columns.findIndex((item: any) => item.id === 'merchantName')
    : storagedData['merchants-fees'].findIndex((item: string) => item === 'merchantName')
  const paymentProviderIdx = !storagedData['merchants-fees']
    ? columns.findIndex((item: any) => item.id === 'paymentGateway')
    : storagedData['merchants-fees'].findIndex((item: string) => item === 'paymentGateway')

    
  const value = merchantIdx > paymentProviderIdx 
    ? _t("payment_provider")
    : _t("merchant")

  const [groupBy, setGroupBy] = useState(value)

  const columnsGroup = storagedData['merchants-fees'] 
    ? storagedData['merchants-fees'].filter((item: any) => item !== 'merchantName' && item !== 'paymentGateway')
    : [...columns].filter((item: any) => item.id !== 'merchantName' && item.id !== 'paymentGateway').map((item: any)=> item.id)

  const  setColumnOrderGroupBy = (setColumnOrder: any) => {
    if (groupBy === _t("merchant") && merchantIdx !== 0) {
      setColumnOrder([ 'merchantName', 'paymentGateway', ...columnsGroup ])
    } else if (groupBy === _t("payment_provider") && paymentProviderIdx !== 0) {
      setColumnOrder([ 'paymentGateway', 'merchantName',  ...columnsGroup ])
    }
  }

  const setColumnOrderPSPToMerchant = (type: string) => {
    if (type === _t("merchant")) {
      setSort([{id: 'merchantName', desc: false}])
    } else if (type === _t("payment_provider") && !!columns){
      setSort([{id: 'paymentGateway', desc: false}])
    } 
    setGroupBy(type)
  }

  return { setColumnOrderPSPToMerchant, setColumnOrderGroupBy, groupBy }
}

export const useGetFormConfigs = () => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const { hideModal } = useModal();

  const useGetFormConfigAdvancedWithCurrency = ({
    fieldName,
    label,
    showHistory = () => { },
    historyLinkProps = {},
  } : PropsForFormConfigAdvanced) => {
    const { currenciesOptions } = useModuleData();

    const formConfig = useMemo((): FormConfigType => {
      return [
        fields.formFields.depositFees({
          name: fieldName,
          componentProps: {
            labelText: label,
          },
          columnParams: {
            className: 'max-width-input small-input__container-right'
          }
        }),
        fields.formFields.selectCurrenciesWithSearch({
          name: (fieldName + "Currency"),
          componentProps: {
            options: currenciesOptions
          },
          columnParams: {
            className: 'align-input max-width-input',
            lg: 4,
            md: 2,
          }
        }),
        fields.formFields.validFrom({
          name: (fieldName + "ValidFrom") ,
          componentProps: {
            openLeft: true
          },
          columnParams: {
            md: 3,
            lg: 6,
          }
        }),
        fields.general.saveBtn({
          componentProps: {
            onClick: () => hideModal('advancedSettings'),
            type: 'button',
            label: _t("advanced_settings"),
            kind: "ghost",
            style: { color: "#5B6871" },
            renderIcon: ChevronUp16,
          }}),
        fields.formFields.treeViewMultiselect(),
        fields.general.historyLink({
          componentProps: {
            showHistory,
            historyLinkProps,
          }
        }),
        fields.general.saveBtn({
          componentProps: {
            renderIcon: Save16,
          },
          columnParams: {
            className: 'advanced__saveBtn'
          }
        }),
      ];
    }, [currenciesOptions]);
  
    return formConfig
  }
  
  const useGetFormConfigAdvancedTooltipAll = ({
    fieldName,
    label,
    showHistory = () => {},
    historyLinkProps = {},
    } : PropsForFormConfigAdvanced) => {
    const formConfig = useMemo((): FormConfigType => {
      return [
        fields.formFields.depositFees({
          name: fieldName ,
          componentProps: {
            labelText: label,
          },
          columnParams: {
            md: 4,
            lg: 8,
          }
        }),
        fields.formFields.validFrom({
          name: (fieldName + 'ValidFrom'),
          columnParams: {
            md: 4,
            lg: 8,
          },
          componentProps: {
            openLeft: true
          }
        }),
        fields.general.saveBtn({
          componentProps: {
            onClick: () => hideModal('advancedSettings'),
            type: 'button',
            label: _t("advanced_settings"),
            kind: "ghost",
            style: { color: "#5B6871", width: '100%' },
            renderIcon: ChevronUp16,
  
          }}),
        fields.formFields.treeViewMultiselect(),
        fields.general.historyLink({
          componentProps: {
            showHistory,
            historyLinkProps,
          }
        }),
        fields.general.saveBtn({
          componentProps: {
            renderIcon: Save16,
          },
          columnParams: {
            className: 'advanced__saveBtn'
          }
        }),
      ];
    }, []);
  
    return formConfig
  }


  const useGetFormConfigTooltipWithCurrency = ({
    fieldName,
    label,
    showHistory = () => { },
    historyLinkProps = {},
    showAdvancedSettings = () => { }
  }: PropsForFormConfig) => {
    const { currenciesOptions } = useModuleData();
    const formConfig = useMemo((): FormConfigType => {
      return [
        fields.formFields.depositFees({
          name: fieldName,
          componentProps: {
            labelText: label,
          },
          columnParams: {
            className: 'max-width-input small-input__container-right'
          }
        }),
        fields.formFields.selectCurrenciesWithSearch({
          name: (fieldName + "Currency"),
          componentProps: {
            options: currenciesOptions
          },
          columnParams: {
            className: 'align-input max-width-input',
            lg: 4,
            md: 2,
          }
        }),
        fields.formFields.validFrom({
          name: (fieldName + "ValidFrom"),
          componentProps: {
            openLeft: true
          },
          columnParams: {
            md: 3,
            lg: 6,
          }
        }),
        fields.general.saveBtn({
          componentProps: {
            onClick: () => showAdvancedSettings(historyLinkProps),
            type: 'button',
            label: _t("advanced_settings"),
            kind: "ghost",
            style: { color: "#5B6871" },
            renderIcon: ChevronDown16,
          }}),
        fields.general.historyLink({
          componentProps: {
            showHistory,
            historyLinkProps,
          }
        }),
        fields.general.divider(),
        fields.general.saveBtn({
          componentProps: {
            renderIcon: Checkmark16,
            label: '',
          },
          columnParams: {
            className: 'popover-tooltip__saveBtn-big'
          }
        }),
      ];
    }, [currenciesOptions]);
  
    return formConfig
  }
  
  const useGetFormConfigTooltipAll = ({
    fieldName,
    label,
    showHistory = () => {},
    historyLinkProps = {},
    showAdvancedSettings = () => { }
    }: PropsForFormConfig) => {

    const formConfig = useMemo((): FormConfigType => {
      return [
        fields.formFields.depositFees({
          name: fieldName,
          componentProps: {
            labelText: label,
          },
          columnParams: {
            md: 4,
            lg: 8,
          }
        }),
        fields.formFields.validFrom({
          name: (fieldName + 'ValidFrom'),
          columnParams: {
            md: 4,
            lg: 8,
          },
          componentProps: {
            openLeft: true
          }
        }),
        fields.general.saveBtn({
          componentProps: {
            onClick: () => showAdvancedSettings(historyLinkProps),
            type: 'button',
            label: _t("advanced_settings"),
            kind: "ghost",
            style: { color: "#5B6871" },
            renderIcon: ChevronDown16,
  
          }}),
        fields.general.historyLink({
          componentProps: {
            showHistory,
            historyLinkProps,
          }
        }),
        fields.general.divider(),
        fields.general.saveBtn({
          componentProps: {
            label: '',
            renderIcon: Checkmark16,
          },
          columnParams: {
            className: 'popover-tooltip__saveBtn'
          }
        }),
      ];
    }, []);
  
    return formConfig
  }

  const useGetFieldsWithCurrency = ({
    fieldName,
    label,
    showHistory,
    historyLinkProps,
    showAdvancedSettings
  }: PropsForFormConfig) => {
    const hooksData: PropsForFormConfigAdvanced = {
      fieldName,
      label,
      showHistory,
      historyLinkProps,
    }

    const advancedFormConfig = useGetFormConfigAdvancedWithCurrency(hooksData)
    const formConfig = useGetFormConfigTooltipWithCurrency({
      ...hooksData,
      showAdvancedSettings: (initialValues: any) => showAdvancedSettings(advancedFormConfig, initialValues)
    })

    return formConfig
  }

  const useGetFieldsAll = ({
    fieldName,
    label,
    showHistory = () => {},
    historyLinkProps = {},
    showAdvancedSettings = () => { }
    } : PropsForFormConfig): FormConfigType => {
    const hooksData: PropsForFormConfigAdvanced = {
      fieldName,
      label,
      showHistory: showHistory,
      historyLinkProps,
    }
    const advancedFormConfig = useGetFormConfigAdvancedTooltipAll(hooksData)
    const formConfig = useGetFormConfigTooltipAll({
      ...hooksData,
      showAdvancedSettings: (initialValues: any) => showAdvancedSettings(advancedFormConfig, initialValues)
    })

    return formConfig
  }

  return {
      useGetFieldsAll,
      useGetFieldsWithCurrency
    }
  }
