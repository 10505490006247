import { useQuery } from "react-query";
import { useApiRequest } from "system/api/hooks";
import { API_GET_PAYMENT_ORCHESTRATION_HISTORY } from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";

const getRequestOrchestationHistoryKey = "getRequestOrchestationHistoryKey";

export const useGetRequestOrchestationHistory = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getRequestOrchestationHistoryKey, JSON.stringify(filters)],
    () =>
      apiRequest({
        url: generateUrlQuery(API_GET_PAYMENT_ORCHESTRATION_HISTORY, filters),
        method: "get",
      }),
  );
};
