import React, { useEffect, useRef, useState } from "react";
import { Button, Column, Grid, Row } from "carbon-components-react";
import { Checkmark16, Close16, CloseOutline16, Edit16 } from "@carbon/icons-react";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useOutsideClick } from "system/helpers/hooks";
import { ChangeValueDialogWindowProps } from "./types";
import ConfirmModalButtons from "../ConfirmModalButtons";
import NotClosedPeriod from "components/molecules/NotClosedPeriod";
import { useModal } from "modules/modal/hooks";
import OverflowClearUpdateMenu from "components/molecules/OverflowClearUpdateMenu";

const ChangeValueDialogWindow: React.FC<ChangeValueDialogWindowProps> = ({
  formConfig,
  text = '',
  initialValues,
  onUpdate = () => {},
  type,
  isEdit,
  onClearValues = () => {},
  validation
}) => {
  const { _t } = useTrans();
  const [ adding, setAdding ] = useState(false);
  const ref = useRef(null)
  const [ clickOutSideWithModal, setClick ] = useOutsideClick(ref);
  const { showModal , hideModal } = useModal()

  const submitForm = () => {
    document.getElementById('routingRulesSaveButton')?.click()
    hideModal(); 
    setAdding(false)
  }

  const openWarning = () => {
    showModal(
      {
        isShowScale: false,
        component: NotClosedPeriod,
        size: 'xs',
        smallModal: true,
        componentProps: {
          data: [],
          titleText:  _t('do_you_want_save_changes_before_closing'),
          secondaryText: _t('changes_you_made_will_not_be_saved'),
          isClosePeriod: true,
        },
        footer: ConfirmModalButtons,
        footerProps:  {
              onOkText: _t('save_and_close'),
              onCancel: () => hideModal(),
              onCancelText: _t('keep_editing'),
              onOk: () => submitForm(),
              onOkKind: "primary",
              className: 'small-modal-full-width-buttons',
              renderIcon: Checkmark16
            }
      });
  }
  

  const openHandler = () => {
    if (!adding && !clickOutSideWithModal) {
      setAdding(true)
    }
  }

  useEffect(() => {
    if (adding && clickOutSideWithModal) {
      openWarning();
      setClick(false)
    }
  }, [clickOutSideWithModal])

  const onSubmit = async (data: any) => {
    const normalizeData = { ...data }
    delete normalizeData.routingRulesSaveButton
    
    onUpdate(normalizeData);
    setAdding(false)
  };

  const actionMenuItems: any = [
    {
      title: _t("edit"),
      onClick: () => setAdding(true),
      icon: <Edit16 /> ,
    },
    {
      title: _t("clear_values"),
      onClick: onClearValues,
      icon: <CloseOutline16 />,
      type: "danger",
    },
  ];
  
  return (
    <div className='change-value-dialog-window__container'>
      <div className="change-value-dialog-window__text" onClick={openHandler}>
        {text}
      </div>
      {isEdit && <OverflowClearUpdateMenu items={actionMenuItems}  />}

        {adding ? (
           <div ref={ref} className='change-value-dialog-window'>
          <Grid style={{ paddingLeft: "1rem", paddingRight: "1rem" }} narrow >
            <Row>
              <Column
                lg={{ span: 16, offset: 0 }}
                md={{ span: 16, offset: 0 }}
                sm={{ span: 16, offset: 0 }}
              >
                <div className='change-value-dialog-window__title'>
                  {_t(type)}
                </div>
           <FormBuilder
             formItemsConfig={formConfig}
             showSubmit={false}
             formProps={{
               submitBtnLabel: _t("update"),
               onSubmit: onSubmit,
               initialValues: initialValues,
               validationSchema: validation
             }}
           />
           <Button
             renderIcon={Close16}
             kind='tertiary'
             style={{
               position: 'absolute',
               bottom: '14.5px',
               right: '88px',
             }}
             onClick={() => {
               setAdding(false);
               }
             }
             hasIconOnly={true}
             iconDescription={_t('cancel')}
           />
              </Column>
            </Row>
          </Grid>
       </div>
        ): null}
    </div>

    
  );
};

export default ChangeValueDialogWindow;