import React from "react";
import { CodeSnippet, Column, Row } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import JSONbig from 'json-bigint';

const Details = (props: any) => {
  const { _t } = useTrans();
  const renderJson = (value: any) => {
    try {
      return JSONbig.stringify(JSONbig.parse(value), null, 2);
    } catch (e) {
        const replacedValueWithAsterisksToString = value.replace(/\b\d+\*+\d+\b/g, '"$&"');
        if (value !== replacedValueWithAsterisksToString) {
          try {
            return JSONbig.stringify(JSONbig.parse(replacedValueWithAsterisksToString), null, 2);
          } catch (e) {
              return value
          }
        } else {
          return value
        }
    }
  };
  return (
    <Row style={{ background: "#F3F5F6", padding: "10px 5px" }}>
      <Column lg={{ span: 16 }}>
        <label className="bx--label">{_t("request_params")}</label>
        <CodeSnippet
          className={"mv-2"}
          type="multi"
          feedback="Copied to clipboard"
        >
          {renderJson(props?.rowData?.requestParams)}
        </CodeSnippet>
      </Column>
      <Column lg={{ span: 8 }}>
        <label className="bx--label">{_t("request_header")}</label>
        <CodeSnippet
          className={"mv-2"}
          type="multi"
          feedback="Copied to clipboard"
        >
          {renderJson(props?.rowData?.requestHeader)}
        </CodeSnippet>
      </Column>
      <Column lg={{ span: 8 }}>
        <label className="bx--label">{_t("response_header")}</label>
        <CodeSnippet
          className={"mv-2"}
          type="multi"
          feedback="Copied to clipboard"
        >
          {renderJson(props?.rowData?.responseHeader)}
        </CodeSnippet>
      </Column>
      <Column lg={{ span: 8 }}>
        <label className="bx--label">{_t("request_data")}</label>
        <CodeSnippet
          className={"mv-2"}
          type="multi"
          feedback="Copied to clipboard"
        >
          {renderJson(props?.rowData?.requestData)}
        </CodeSnippet>
      </Column>
      <Column lg={{ span: 8 }}>
        <label className="bx--label">{_t("response_data")}</label>
        <CodeSnippet
          className={"mv-2"}
          type="multi"
          feedback="Copied to clipboard"
        >
          {renderJson(props?.rowData?.responseData)}
        </CodeSnippet>
      </Column>
    </Row>
  );
};

export default Details;
