import { Checkmark16 } from "@carbon/icons-react";
import Btn from "components/atoms/Btn";
import NotClosedPeriod from "components/molecules/NotClosedPeriod";
import _ from "lodash";
import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { useModal } from "modules/modal/hooks";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { useGetSettingCountries, useGetSettingCurrencies } from "modules/settings/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useTrans } from "system/translations/hooks";
import { useGetBinsParams, useGetMerchantPaymentMethods, useGetPaymentGatewaysByMerchant, useGetPaymentMethodsWithId, useGetTemplatesTypes } from "./apiHooks";
import config from "./constants";
import { AuthType, BinsParamsType, UseModuleDataForSearchRes, UseModuleDataRes } from "./types";


export const useModuleData = (selectedMerchantId: number | undefined = undefined): UseModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: templatesTypesList = [] } = useGetTemplatesTypes();
  const { data: paymentMethodsByMerchant = [] } = useGetMerchantPaymentMethods(selectedMerchantId);
  const { data: paymentGateways = [] } = useGetPaymentGatewaysByMerchant(selectedMerchantId);

  const { data: currencies = [] } = useGetSettingCurrencies();
  const { data: countries = [] } = useGetSettingCountries();
  const { data: bins = {} } = useGetBinsParams();

  const merchantsOptionsUpdate = merchantsList.map((item) => ({
    label: item.name,
    value: item,
  }));

  const templatesTypesOptions = arrayOptionsMap(templatesTypesList, {
    labelKey: "name",
    valueKey: "key",
  });
  const paymentMethodsByMerchantOptions = arrayOptionsMap(paymentMethodsByMerchant, {
    labelKey: "method",
    valueKey: "method",
  });
  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "label",
  });
    const currenciesOptions = arrayOptionsMap(currencies, {
    labelKey: "codeLiteral",
    valueKey: "id",
  });
  const countriesOptions = arrayOptionsMap(countries, {
      labelKey: "description",
      valueKey: "code3",
    });

  const cardCategoryOptions = arrayOptionsMap((bins as BinsParamsType)?.cardCategories, {
    labelKey: "value",
    valueKey: "value",
  });

  const cardNetworksOptions = arrayOptionsMap((bins as BinsParamsType)?.cardNetworks, {
    labelKey: "value",
    valueKey: "value",
  });

  const cardFundingTypeOptions = arrayOptionsMap((bins as BinsParamsType)?.cardTypes, {
    labelKey: "value",
    valueKey: "value",
  });
  
  config.condition.currency.valueOptions = currenciesOptions
  config.condition.loginCountry.valueOptions = countriesOptions
  config.condition.issuerCountry.valueOptions = countriesOptions
  config.condition.cardCategory.valueOptions = cardCategoryOptions
  config.condition.cardNetwork.valueOptions = cardNetworksOptions
  config.condition.cardFundingType.valueOptions = cardFundingTypeOptions

  return { paymentGatewaysOptions, merchantsOptionsUpdate, paymentMethodsByMerchantOptions, templatesTypesOptions };
};

export const useModuleDataForSearch = (): UseModuleDataForSearchRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentMethods = [] } = useGetPaymentMethodsWithId();
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });

  const paymentMethodsOptions = arrayOptionsMap(paymentMethods, {
    labelKey: "method",
    valueKey: "method",
  });

  return { merchantsOptions, paymentMethodsOptions };
};

export const useGetUpdateRoutingRulesTreeItems = (onChange: any, data: any) => {
  const { onConfirm } = useConfirmModal();
  // const chartCodeStart = 65
  const addAttributHandler = (item: any, isFirstLevel: boolean = false) => {
    isFirstLevel
      ? data.push({ conditions: [{}] })
      : item.attributes ? item.attributes.push({ conditions: [{}] }) : item.attributes = [{ conditions: [{}] }]
    // isFirstLevel
    //   ? data.push({ conditions: [{attributeName: ["Attribute", data[data.length -1]?.conditions[0]?.attributeName[1] + 1 || 1, String.fromCharCode(chartCodeStart), "Condition 1"] }] })
    //   : item.attributes ? item.attributes.push({ conditions: [{ attributeName: ['asda', 0] }] }) : item.attributes = [{ conditions: [{ attributeName: "Example 3" }]}]
      onChange((prev: any) => (_.cloneDeep(prev)))
  }

  const addAttributConditonsGroupHandler = (item: any, isFirstLevel: boolean = false) => {
    isFirstLevel
      ? data.push({ conditions: [{ operator: 'and', conditions: [{}, {}] }] })
      : item.attributes ? item.attributes.push({ conditions: [{ operator: 'and', conditions: [{}, {}] }] }) : item.attributes = [{ conditions: [{ operator: 'and', conditions: [{}, {}] }] }]
      // ? data.push({ conditions: [{ operator: 'and', attributeName: ['asda', 0], conditions: [{}, {}] }]})
      // : item.attributes ? item.attributes.push({ conditions: [{ operator: 'and', attributeName: "Example 4", conditions: [{}, {}] }]}) : item.attributes = [{ conditions: [{ operator: 'and', attributeName: "Example 5", conditions: [{}, {}] }] }]
    onChange((prev: any) => (_.cloneDeep(prev)))
  }

  const addStrategyHandler = (item: any) => {
    item.strategy = {}
    onChange((prev: any) => (_.cloneDeep(prev)))
  }

  const addConditionsHandler = (item: any) => {
    item.conditions.push({})
    item.operator = item.operator ? item.operator : 'and'
    onChange((prev: any) => (_.cloneDeep(prev)))
  }

  const addConditionsGroupHandler = (item: any) => {
    item.conditions.push({
      operator: 'and',
      conditions: [{}, {}]
    })
    item.operator = item.operator ? item.operator : 'and'
    onChange((prev: any) => (_.cloneDeep(prev)))
  }

  const operatorChangerHandler = (item: any, operator: 'and' | 'or') => {
    if (item.operator !== operator) {
      item.operator = operator
      onChange((prev: any) => (_.cloneDeep(prev)))
    }
  }

  const onRemoveStrategy = (item: any) => {
    delete item.strategy
    onChange((prev: any) => (_.cloneDeep(prev)))
  }

  const findAndDeleteAttributes = (data: any, itemToSearch: any): any => {
    if (!data?.attributes){
      return
    }
    
   const idx = data.attributes.findIndex((el: any) => el.conditions === itemToSearch)

    if (idx !== -1) {
      if (data.attributes.length > 1) {
        data.attributes.splice(idx, 1)
      } else {
        delete data.attributes
      }
    } else {
      return data.attributes.forEach((attribute: any) => {
        if (attribute.attributes) {
          findAndDeleteAttributes(attribute, itemToSearch)
        }
      })  
    }
  }

  const findAndDeleteOperator = (data: any, itemToSearch: any): any => {
    if (!data?.attributes) {
      return
    }

    data.attributes.forEach((attribute: any) => {
      if (attribute.conditions === itemToSearch) {
        delete attribute.operator
      } else {
        findAndDeleteOperator(attribute, itemToSearch)
      }
    })
  }

  const onRemoveAttribute = (idx: number, authType: AuthType) => {
    if (data.length === 3) {
      data.splice(idx, 1)
      onChange((prev: any) => (_.cloneDeep(prev)))
    } else if (data.length === 2) {
        onChange((prev: any) => {
          findAndDeleteOperator(prev[authType], data);
          data.splice(idx, 1)
          return (_.cloneDeep(prev))
        })
    } else {
      onConfirm({ onOk: () => {
        onChange((prev: any) => {
          findAndDeleteAttributes(prev[authType], data);
          return (_.cloneDeep(prev))
        })
      }})
    }
  }

  const onUpdateStrategy = (dataToUpdate: any, item: any) => {
    item.strategy = {...dataToUpdate}

    if (item.strategy.strategyType === "weight") {
      item.strategy.depositSettings.forEach((item: any) => delete item?.paymentMethodSettings?.weightValuePercentage)
    } else if (item.strategy.strategyType === "priority") {
      item.strategy.depositSettings.forEach((item: any, index: number) => {
        if (item.paymentMethodSettings) {
          item.paymentMethodSettings.strategyValue = index

        }
      })
    }

    onChange((prev: any) => (_.cloneDeep(prev)))
  }

  const onUpdateConditions = (dataToUpdate: any, idx: number) => {
    data[idx] = {...dataToUpdate}
    onChange((prev: any) => (_.cloneDeep(prev)))
    
  }

  const clearStrategyValues = (item: any) => {
    onConfirm({ onOk: () => {
      item.strategy = {}
      onChange((prev: any) => (_.cloneDeep(prev)))
    }})
  }

  const clearConditionsValues = (item: any) => {
    onConfirm({ onOk: () => {
      if (item.conditions) {
        item.conditions = [{}, {}]
        item.operator = "and"
      } else {
        delete item.operator
        delete item.property
        delete item.value
      }
      onChange((prev: any) => (_.cloneDeep(prev)))
    }})
  }

  const removeAllProviderValueInStrategys = (data: any) => {
    if (data?.strategy?.depositSettings) {
      data.strategy.depositSettings = [{}]
    }

    if (data?.attributes?.length > 0) {
      data?.attributes.forEach((attributeLevel: any) => {
        removeAllProviderValueInStrategys(attributeLevel)
      })
    } else {
      return
    }
  }

  return {
    addAttributHandler,
    addAttributConditonsGroupHandler,
    addStrategyHandler,
    addConditionsHandler,
    addConditionsGroupHandler,
    operatorChangerHandler,
    onRemoveStrategy,
    onRemoveAttribute,
    onUpdateStrategy,
    onUpdateConditions,
    clearStrategyValues,
    clearConditionsValues,
    removeAllProviderValueInStrategys
  }
}

export const useGetParametrsRoutingRulesTreeItems = () => {

  const getStrategyParametrs = (data: any) => {

    if (data.depositSettings === null) {
      data.depositSettings = [{}]
    }
    //unnecessary designate and condition
    const parametrs = data?.strategyType 
    ? data?.strategyType === 'direct' 
      ? data.depositSettings[0]?.paymentMethod || ''
      : data.depositSettings.filter((item: any) => !!item.paymentMethod && !!item?.paymentMethodSettings?.active).map((item: any) => item.paymentMethod).join(', ')
    : ''
    if (!parametrs.length) {
      return 'no set'
    }
    const type = data?.strategyType.charAt(0).toUpperCase() + data?.strategyType.slice(1)


    return `${type} (${parametrs})`
  }

  const prepareConditionsParametrs = (data: any) => {
    if (!data?.property) {
      return 'no set'
    } else if (data.property && !data.operator) {
      return `${data.property} [no set]`
    } else if (data.property && data.operator && !data.value) {
      return `${data.property} [${data.operator}] [no set]`
    }

    return `${data.property} [${data.operator}] [${data.value}]` 
  }

  const getConditionParametrs = (data: any) => {
    if (!data.conditions) {
      return prepareConditionsParametrs(data)
    } 

    if (data.conditions.length > 1) {
      let parametrs = ''

      data.conditions.forEach((item: any, index: any) => {
        parametrs += prepareConditionsParametrs(item)

        if (index < data.conditions.length - 1) {
          parametrs += ` ${data.operator} `
        }
        
      })
      return parametrs
    }

    return  prepareConditionsParametrs(data.conditions[0])
    
  }


  return {
    getStrategyParametrs,
    getConditionParametrs
  }
}


export const useGetWarningSaveDataWithoutDefaultStartegy = () => {
  const { _t } = useTrans()
  const  {showModal, hideModal} = useModal()
  const openWarning = ({
    warningType, 
    titleText = _t("warning_not_created_default_stategy_for"),
    secondaryText = _t('promt_to_save_rule_without_default_startegy')
  }: {
    warningType:  string[],
    titleText?: string,
    secondaryText?: string
  }) => {
    showModal(
      {
        isShowScale: false,
        component: NotClosedPeriod,
        size: 'xs',
        smallModal: true,
        componentProps: {
          data: [...warningType],
          titleText:  titleText,
          secondaryText: secondaryText,
          isClosePeriod: false,
        },
        footer: Btn,
        footerProps:  {
          onClick: () => hideModal(),
          renderIcon: Checkmark16,
          hasIconOnly: true,
        }
      });
  }

  const checkDefaultStrategy = (data: any, key: string ) => {
    const strategy = data[key].strategy

    switch(true) {
      case !strategy.strategyType:
        return false;
      case strategy.strategyType === 'direct' && !strategy.depositSettings[0].paymentMethod:
       return false;
      case strategy.strategyType === 'weight':
        return strategy.depositSettings.some((item: any) => item.paymentMethod && item.paymentMethodSettings.active)
      default:
        return true
    }
  }

  const checkDefaultStrategyAndDisplayWarningOrSaveData = (rules: any, saveDataOperation: any) => {
    switch(true) {
      case !checkDefaultStrategy(rules,'token') && !checkDefaultStrategy(rules,'newInstrument'):
        openWarning({ warningType : ['Token', "New Instrument"] })
        break;
      case !checkDefaultStrategy(rules,'token'): 
        openWarning({ warningType: ['Token'] })
        break;
      case !checkDefaultStrategy(rules,'newInstrument'):
        openWarning({ warningType: ['New Instrument'] })
        break;
      default: 
        saveDataOperation()
    }
  }

  const openWarningWhenMerchantNotSelected = (selectedMerchantId: number | undefined) => {
    !selectedMerchantId  && openWarning({ 
      warningType: [''],
      titleText: _t('you_cannot_create_rule_without_selected_merchant'),
      secondaryText: _t('select_merchant_and_try_again')
    })
  }

  return { checkDefaultStrategyAndDisplayWarningOrSaveData , openWarning, openWarningWhenMerchantNotSelected }  
}

export const useGetConfirmationsToChange = () => {
  const {_t} = useTrans()
  const { onConfirm } = useConfirmModal();

  const onChangeMerchantConfirmHandler = ({
    onOk,
    value,
    valueAfterChange
  }: any) => {
    value === undefined 
    ? onOk()
    : onConfirm({ 
      onOk: onOk,
      bodyText: _t("change_{merchantFrom}_to_{merchantTo}_warning", {
        merchantFrom: value?.name ,
        merchantTo: valueAfterChange?.name || 'empty value'
      })
    });
  }


  return { onChangeMerchantConfirmHandler }
}

export const useGetApplyToAllCheckBoxHandlers = () => {
  const {_t} = useTrans()
  const { onConfirm } = useConfirmModal();
  const  {showModal, hideModal} = useModal()

  const getConfirmationModalOnApplyToAllClick = ({
    onOk,
  }: any) => {

    onConfirm({ 
      modalHeading: _t('are_you_sure_apply_changes_to_all_strategies'),
      onOk: onOk,
      bodyText: _t('this_action_will_replicate_current_settings_to_another')
    });
  }

  const getWarningModalOnChangeDataWhenApplyToAllChecked = () => {
    showModal(
      {
        isShowScale: false,
        component: NotClosedPeriod,
        size: 'sm',
        smallModal: true,
        componentProps: {
          data: [],
          titleText:  _t('you_have_modified_data_one_of_the_strategies'),
          secondaryText: _t('warning_apply_to_all_option_has_been_unchecked'),
          isClosePeriod: false,
        },
        footer: Btn,
        footerProps:  {
          onClick: () => hideModal(),
          renderIcon: Checkmark16,
          hasIconOnly: true,
        }
      });
  }

  const applyToAllClickHandler = (value: boolean,isApplyToAll:boolean, setIsApplyToAll: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!isApplyToAll) {
      getConfirmationModalOnApplyToAllClick({ onOk: () => setIsApplyToAll(value) })
    } else {
      setIsApplyToAll(value)
    }
  }


  return { getConfirmationModalOnApplyToAllClick, applyToAllClickHandler, getWarningModalOnChangeDataWhenApplyToAllChecked }
}
