export const APP_ROUTES = {
  actionLog: "/action-log",
  appSettings: "/app-settings",
  requestHistory: "/request-history",
  login: "/login",
  verified: "/verified",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  gateWay: {
    whitelist: {
      index: "/gateway-whitelist/:pgwId",
      create: "/gateway-whitelist/:pgwId/create",
      update: "/gateway-whitelist/:pgwId/:id",
    },
  },
  merchants: {
    index: "/merchants/",
    create: "/merchants/create",
    update: "/merchants/:id",
    sub: {
      index: "/merchants/sub/:merchantId",
      create: "/merchants/sub/create/:id",
      update: "/merchants/sub/update/:id",
      payments: {
        index: "/merchants/sub/:merchantId/payments",
        create: "/merchants/sub/:merchantId/payments/create",
        update: "/merchants/sub/:merchantId/payments/:type/:id",
      },
      blacklist: {
        index: "/merchants/sub/:merchantId/blacklist",
        create: "/merchants/sub/:merchantId/blacklist/create",
        update: "/merchants/sub/:merchantId/blacklist/:id",
      },
      whitelist: {
        index: "/merchants/sub/:merchantId/whitelist",
        create: "/merchants/sub/:merchantId/whitelist/create",
        update: "/merchants/sub/:merchantId/whitelist/:id",
      },
    },
    payments: {
      index: "/merchants/:merchantId/payments",
      create: "/merchants/:merchantId/payments/create",
      update: "/merchants/:merchantId/payments/:type/:id",
    },
    blacklist: {
      index: "/merchants/:merchantId/blacklist",
      create: "/merchants/:merchantId/blacklist/create",
      update: "/merchants/:merchantId/blacklist/:id",
    },
    whitelist: {
      index: "/merchants/:merchantId/whitelist",
      create: "/merchants/:merchantId/whitelist/create",
      update: "/merchants/:merchantId/whitelist/:id",
    },
  },
  merchantsBalance: "/merchants-balance",
  merchantsBalanceV2: {
    index: "/merchants-balance-v2",
    merchantsFees: {
      index: "/merchants-fees",
      update: "/merchants-fees/:merchantId/:id",
      create: "/merchants-fees/create"
    },
    merchantConfiguration: {
      index: "/merchants-balance-V2-merchant-configuration",
      update: "/merchants-balance-V2-merchant-configuration/:merchantId/:paymentGateway/:id",
      create: "/merchants-balance-V2-merchant-configuration/create"
    },
    balanceHistory: "/merchants-balance-V2-history",
    detailsInCurrency: {
      index: "/merchants-balance-v2/:merchantId/:currency/:fromDate/:toDate/details",
      create: "/merchants-balance-v2/:merchantId/:currency/:fromDate/:toDate/details/create"
    }
  },
  credentials: {
    index: "/credentials",
    create: "/credentials/:credentialId/create",
    update: "/credentials/:credentialId/:id",
    info: "/credentials/:credentialId/",
  },
  settings: {
    index: "/settings",
    create: "/settings/:settingId/create",
    update: "/settings/:settingId/:id",
    info: "/settings/:settingId",
    duplicate: "/settings/:settingId/:id/create",
  },
  roles: {
    index: "/roles",
    create: "/roles/create",
    update: "/roles/:id",
  },
  analytics: "/analytics",
  transactions: {
    index: "/",
    update: "/update-status",
  },
  translations: {
    index: "/translations",
    create: "/translations/create",
    update: "/translations/:id",
  },
  users: {
    index: "/users",
    create: "/users/create",
    update: "/users/:id",
  },
  monitoring: {
    index: "/monitoring",
    create: "/monitoring/create",
    update: "/monitoring/:id",
  },
  pendingTransactionsMonitoring: {
    index: "/pending-transactions-monitoring",
    create: "/pending-transactions-monitoring/create",
    update: "/pending-transactions-monitoring/:id",
  },
  monitoringMerchant: {
    index: "/monitoring-merchant",
    create: "/monitoring-merchant/create",
    update: "/monitoring-merchant/:id",
  },
  alerts: {
    index: "/alerts",
    create: "/alerts/create",
    update: "/alerts/:id",
  },
  admin: {
    change_password: "/change-password",
  },
  paymentOrchestration: {
    routingRules: {
      index: "/routing-rules",
      update: "/routing-rules/:id",
      create: "/routing-rules/create",
    },
    providerConstraints: {
      index: "/provider-constraints"
    },
    coverageReport: {
      index: "/coverage-report"
    },
  },
  swagger: {
    index: "/documentation"
  },
  paymentInstrumentManagement: {
    index: "/payment-instrument-management"
  },
  statusReasons: {
    index: "/status-reasons",
    create: "/status-reasons/create",
    update: "/status-reasons/update/:id",
  },
  statusReasonsConnection: {
    index: "/status-reasons-connections",
    create: "/status-reasons-connections/create",
    update: "/status-reasons-connections/update/:gatewayCode/:type",
  }
};
