import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { UseModuleDataRes } from "modules/transactions/types";
import {
  useGetPaymentGateways,
  useGetPaymentMethods,
} from "../payment/apiHooks";
import { useTrans } from "system/translations/hooks";
import { useModal } from "modules/modal/hooks";
import Checkbox from "components/atoms/Checkbox";
import { Button } from "carbon-components-react";
import { DocumentExport16 } from "@carbon/icons-react";

export const useModuleData = (): UseModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentMethods = [] } = useGetPaymentMethods();
  const { data: paymentGateways = [] } = useGetPaymentGateways();
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  const paymentMethodsOptions = arrayOptionsMap(paymentMethods, {
    labelKey: "label",
    valueKey: "value",
  });
  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  return { merchantsOptions, paymentMethodsOptions, paymentGatewaysOptions };
};

export const useGetExportConfirmationModal = () => {
  const { _t } = useTrans()
  const { showModal, updateModal, hideModal } = useModal()

  const getModalProps = (withDeviceData: boolean, onExport: any) => {
    return {
      size: "sm",
      modalHeading: _t('do_you_want_make_report_with_device_data'),
      component: Checkbox,
      componentProps: {
        value: withDeviceData,
        labelText: _t('include_device_data_to_export_report'),
        onChange: (e: boolean) => onUpdateCheckBox(e, onExport),
        className: 'checkbox--with-margin-bottom'
      },
      isShowScale: false,
      footer: Button,
      footerProps: {
        onClick: () => {
          onExport({ deviceData: withDeviceData })
          hideModal()
        },
        children: _t("export_CSV"),
        renderIcon: DocumentExport16
      },
    }
  }

  const onUpdateCheckBox = (withDeviceData: boolean, onExport: any) => {
    updateModal(getModalProps(withDeviceData, onExport))
  }

  const onExportWithDeviceDataModal = (onExport: any) => {
    showModal(getModalProps(true, onExport));
  }

  return { onExportWithDeviceDataModal }
}
