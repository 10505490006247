import React, { useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";
import { useGetFormConfigs, useModuleData, useModuleDataCreate } from "./hooks";
import { Link } from "carbon-components-react";
import DotsMenu from "components/atoms/DotsMenu";
import ChangeValueTooltip from "components/molecules/ChangeValueTooltip";
import { useUpdateMerchantsFees } from "./apiHooks";
import RadioButton from "components/atoms/RadioButton";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const { useGetFieldsWithCurrency, useGetFieldsAll } = useGetFormConfigs()
  
  const { mutate: onUpdate } = useUpdateMerchantsFees();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("merchant"),
        accessor: "merchantName",
        id: "merchantName",
      },
      {
        Header: _t("payment_provider"),
        accessor: "paymentGateway",
        id: "paymentGateway",
        Cell: ({ row, onUpdate }: any) => {
          
          return (<Link
            onClick={() => {
              onUpdate({
                id: row.original?.id,
                merchantId: row.original?.merchantId,
              });
            }}
          >
            {row.original?.paymentGatewayName}
          </Link>
          )
        }
      },
      {
        Header: _t("payment_gateway"),
        accessor: "paymentMethod",
        id: "paymentMethod",
        Cell: ({ row, onUpdate }: any) => {
          return row.original?.paymentGateway ? row.original?.paymentGateway : '-'
        }
      },
      {
        Header: _t("deposit_fees"),
        accessor: "depositFee",
        id: "depositFee",
        Cell: ({ row, showHistory, showAdvancedSettings }: any) => {
          const hooksData = {
            fieldName:"depositFee",
            label:`${_t("deposit_fees")}, %`,
            showHistory:showHistory,
            historyLinkProps:{ ...row.original, fieldName: "depositFee" },
            showAdvancedSettings: showAdvancedSettings
          }
          const formConfig = useGetFieldsAll(hooksData)

          return (
            <ChangeValueTooltip
              formConfig={formConfig}
              dataValue={row.original?.depositFee 
                ? row.original?.depositFee + ' %' 
                : '-'
              }
              initialValues={row.original}
              bigTooltipClass="smallTooltipClass"
              onUpdate={onUpdate}
            />
          )
        }
      },
      {
        Header: _t("deposit_added_fees"),
        accessor: "depositAddedFee",
        id: "depositAddedFee",
        Cell: ({ row, showHistory , showAdvancedSettings}: any) => {
          const hooksData = {
            fieldName:"depositAddedFee",
            label:`${_t("deposit_added_fees")}, %`,
            showHistory: showHistory,
            historyLinkProps:{ ...row.original, fieldName: "depositAddedFee" },
            showAdvancedSettings: showAdvancedSettings
          }
          const formConfig = useGetFieldsAll(hooksData)

          return (
            <ChangeValueTooltip
              formConfig={formConfig}
              dataValue={row.original?.depositAddedFee 
                ? row.original?.depositAddedFee + ' %' 
                : '-'
              }
              initialValues={row.original}
              bigTooltipClass="smallTooltipClass"
              onUpdate={onUpdate}
            />
          )
        }
      },
      {
        Header: _t("withdrawal_fees"),
        accessor: "withdrawalFee",
        id: "withdrawalFee",
        Cell: ({ row, showHistory, showAdvancedSettings }: any) => {
          const hooksData = {
            fieldName: "withdrawalFee",
            label: `${_t("withdrawal_fees")}, %`,
            showHistory: showHistory,
            historyLinkProps: { ...row.original, fieldName: "withdrawalFee" },
            showAdvancedSettings: showAdvancedSettings
          }
          const formConfig = useGetFieldsAll(hooksData)
          return (
            <ChangeValueTooltip
              formConfig={formConfig}
              dataValue={row.original?.withdrawalFee 
                ? row.original?.withdrawalFee + ' %' 
                : '-'
              }
              initialValues={row.original}
              bigTooltipClass="smallTooltipClass"
              onUpdate={onUpdate}
            />
          )
        }
      },
      {
        Header: _t("withdrawal_added_fees"),
        accessor: "withdrawalAddedFee",
        id: "withdrawalAddedFee",
        Cell: ({ row, showHistory, showAdvancedSettings }: any) => {
          const hooksData = {
            fieldName: "withdrawalAddedFee",
            label:`${_t("withdrawal_added_fees")}, %`,
            showHistory:showHistory,
            historyLinkProps:{ ...row.original, fieldName: "withdrawalAddedFee" },
            showAdvancedSettings: showAdvancedSettings
          }
          const formConfig = useGetFieldsAll(hooksData)

          return (
            <ChangeValueTooltip
              formConfig={formConfig}
              dataValue={row.original?.withdrawalAddedFee 
                ? row.original?.withdrawalAddedFee + ' %' 
                : '-'
              }
              initialValues={row.original}
              bigTooltipClass="smallTooltipClass"
              onUpdate={onUpdate}
            />
          )
        }
      },
      {
        Header: _t("deposit_fixed_fee"),
        accessor: "depositFixedFee",
        id: "depositFixedFee",
        Cell: ({ row, showHistory, showAdvancedSettings }: any) => {
          const hooksData = {
            fieldName: "depositFixedFee",
            label: _t("deposit_fixed_fee"),
            showHistory: showHistory,
            historyLinkProps: { ...row.original, fieldName: "depositFixedFee" },
            showAdvancedSettings: showAdvancedSettings
          }
          const formConfig = useGetFieldsWithCurrency(hooksData)

            return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={row.original?.depositFixedFee
                  ? row.original?.depositFixedFee + " " + row.original?.depositFixedFeeCurrency
                  : '-'}
                initialValues={row.original}
                bigTooltipClass="bigTooltipClass"
                onUpdate={onUpdate}
              />
            )
          }
      },
      {
        Header: _t("withdrawal_fixed_fee"),
        accessor: "withdrawalFixedFee",
        id: "withdrawalFixedFee",
        Cell: ({ row, showHistory, showAdvancedSettings }: any) => {
          const hooksData = {
            fieldName: "withdrawalFixedFee",
            label: _t("withdrawal_fixed_fee"),
            showHistory: showHistory,
            historyLinkProps: { ...row.original, fieldName: "withdrawalFixedFee" },
            showAdvancedSettings: showAdvancedSettings
          }
          const formConfig = useGetFieldsWithCurrency(hooksData)

            return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={row.original?.withdrawalFixedFee
                  ? row.original?.withdrawalFixedFee + " " + row.original?.withdrawalFixedFeeCurrency
                  : '-'}
                initialValues={row.original}
                bigTooltipClass="bigTooltipClass"
                onUpdate={onUpdate}
              />
            )
          } 
          
      },
      {
        Header: _t("settlement_fees_percentage"),
        accessor: "settlementFee",
        id: "settlementFeePercentage",
        Cell: ({ row, showHistory, showAdvancedSettings }: any) => {
          const hooksData = {
            fieldName: "settlementFee",
            label: _t("settlement_fees_percentage"),
            showHistory:showHistory,
            historyLinkProps:{ ...row.original, fieldName: "settlementFee" },
            showAdvancedSettings: showAdvancedSettings
          }
          const formConfig = useGetFieldsAll(hooksData)
          const settlementFeesInitialValue = {
            ...row.original
          }

          if (!(row.original?.settlementFee && !row.original?.settlementFeeCurrency)) {
            settlementFeesInitialValue.settlementFeeCurrency = null
            delete settlementFeesInitialValue.settlementFee
          }
          return (
            <ChangeValueTooltip
              formConfig={formConfig}
              dataValue={row.original?.settlementFee && !row.original?.settlementFeeCurrency 
                ? row.original?.settlementFee + " %"
                : '-'
              }
              initialValues={settlementFeesInitialValue}
              bigTooltipClass="smallTooltipClass"
              onUpdate={onUpdate}
            />
          )
        }
      },
      {
        Header: _t("settlement_fees_fixed"),
        accessor: "settlementFee",
        id: "settlementFeeFixed",
        Cell: ({ row, showHistory, showAdvancedSettings }: any) => {
          const hooksData = {
            fieldName: "settlementFee",
            label: _t('settlement_fees'),
            showHistory: showHistory,
            historyLinkProps: { ...row.original, fieldName: "settlementFee" },
            showAdvancedSettings: showAdvancedSettings
          }
          const formConfig = useGetFieldsWithCurrency(hooksData)
          const settlementFeesInitialValue = {
            ...row.original
          }
          !(row.original?.settlementFee && row.original?.settlementFeeCurrency) && delete settlementFeesInitialValue.settlementFee
            return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={row.original?.settlementFee && row.original?.settlementFeeCurrency 
                  ? row.original?.settlementFee + " " + row.original?.settlementFeeCurrency
                  : '-'}
                initialValues={settlementFeesInitialValue}
                bigTooltipClass="bigTooltipClass"
                onUpdate={onUpdate}
              />
            )
          } 
      },
      {
        maxWidth: 40,
        Header: "",
        id: "actionMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                merchantId: props?.row?.original?.merchantId,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useTableHistoryColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("date"),
        accessor: "updatedDate",
        id: "updatedDate",
      },
      {
        Header: _t("author"),
        accessor: "updatedBy",
        id: "updatedBy",
      },
      {
        Header: _t("values_before_change"),
        accessor: "valueBefore",
        id: "valueBefore",
        Cell: ({ row }: any) => {
          // return row.original?.valueBefore + ' %' + ' + ' + row.original?.fixedFeeBefore + ' ' + row.original?.fixedFeeCurrencyBefore
          return row.original?.fieldName === "depositFixedFee" ? row.original?.valueBefore : row.original?.valueBefore + ' %'
        }
      },
      {
        Header: _t("values_after_change"),
        accessor: "valueAfter",
        id: "valueAfter",
        Cell: ({ row }: any) => {
          // return row.original?.feeAfter != 'null'
          //   ? (row.original?.feeAfter + ' %' + ' + ' + row.original?.fixedFeeAfter + ' ' + row.original?.fixedFeeCurrencyAfter)
          //   : '-'
          return row.original?.fieldName === "depositFixedFee" 
          ? row.original?.valueAfter
          : row.original?.valueAfter != 'null'
            ? row.original?.valueAfter + ' %'
            : '-'
        }
      },

    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (setColumnOrderPSPToMerchant: any, groupBy: string): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const { merchantsOptions, paymentGatewaysOptions } = useModuleData();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.merchantId({
        componentProps: {
          items: merchantsOptions,
        },
      }),
      fields.filters.paymentGateway({
        name: 'paymentGateway',
        componentProps: {
          items: paymentGatewaysOptions,
          label: _t("payment_provider"),
          withAsteriskOnSelectAll: true
        },
      }),
      fields.filters.groupBy({
        componentProps: {
          onValueChange: setColumnOrderPSPToMerchant
        }
      }),
      fields.filters.filterValue({
        name: groupBy === _t('merchant') 
          ? "merchantName"
          : 'paymentGatewayName',
        componentProps: {
          showSearch: true,
          type: "submit",
          placeHolderText: groupBy === _t('merchant') 
            ? _t("search_by_merchant")
            : _t("search_by_psp")
        },
        columnParams: {
          md: 6,
          lg: 8,
        },
      }),
      fields.general.submitBtn({ emptySpace: {} }),
    ];
  }, [merchantsOptions, paymentGatewaysOptions]);
  return formConfig;
};

export const useCreateEditConfig = (initialValues: any = {}): FormConfigType => {
  const { _t } = useTrans()
  const fields = useDefaultFieldsConfig();
  const [ merchantId, setMerchantId ] = useState(0)
  const [ isAllGatewaysDisplay, setIsAllGatewaysDisplay ] = useState(false);
  const [ isSettlementFeeTypeFixed, setIsSettlementFeeTypeFixed ] = useState(!!initialValues?.settlementFeeCurrency)
  
  const {
    paymentGatewaysOptionsByMerchant,
    paymentGatewaysOptionsAll,
    merchantsOptions,
    currenciesOptions
  } = useModuleDataCreate(merchantId);
  
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.merchantIdSingle({
        componentProps: {
          items: merchantsOptions,
          light: false,
          onFieldChange: (value: any) => {
            setMerchantId(value?.id ? value?.id : 0);
          },
        },
      }),
      fields.formFields.getAllGateways({
        componentProps:{
          value: isAllGatewaysDisplay,
          onFieldChange: (value: any, values: any, form: any) => {
              form.setFieldValue('paymentProviders', "")
              form.setFieldTouched('paymentProviders', false);
              setIsAllGatewaysDisplay(value)
          },
        },
      }),
      fields.formFields.paymentProviders({
        componentProps: {
          labelText: _t('payment_providers'),
          options: isAllGatewaysDisplay ? paymentGatewaysOptionsAll : paymentGatewaysOptionsByMerchant,
          whiteBackground: true,
          wrapClassPrefix: 'dropdown-full-width',
          withTitleText: true,
          previewSelectedItems: true,
        },
      }),
      fields.formFields.depositFees({
        columnParams: {
          className: 'small-input__container-right',
          lg: 11,
          md: 5,
        }
      }),
      fields.formFields.validFrom({
        name: 'depositFeeValidFrom',
        columnParams: {
          lg: 5,
        },
      }),
      fields.formFields.depositFees({
        name: 'depositAddedFee',
        componentProps: {
          labelText: _t('deposit_added_fees') + ', %'
        },
        columnParams: {
          className: 'small-input__container-right',
          lg: 11,
          md: 5,
        }
      }),
      fields.formFields.validFrom({
        name: 'depositAddedFeeValidFrom',
        columnParams: {
          lg: 5,
        },
      }),
      fields.formFields.depositFees({
        name: 'depositFixedFee',
        componentProps: {
          labelText: _t('deposit_transactional_fees')
        },
        columnParams: {
          className: 'small-input__container-right',
          lg: 7,
          md: 3,
        }
      }),
      fields.formFields.selectCurrenciesWithSearch({
        name: 'depositFixedFeeCurrency',
        componentProps: {
          options: currenciesOptions
        },
        columnParams: {
          lg: 4,
          md: 2,
          className: 'max-width-input small-input__container-right'
        }
      }),
      fields.formFields.validFrom({
        name: 'depositFixedFeeValidFrom',
        columnParams: {
          lg: 5,
        },
      }),
      fields.general.divider(),
      fields.formFields.withdrawalFees({
        columnParams: {
          className: 'small-input__container-right',
          lg: 11,
          md: 5,
        }
      }),
      fields.formFields.validFrom({
        name: 'withdrawalFeeValidFrom',
        columnParams: {
          lg: 5,
        },
      }),
      fields.formFields.withdrawalFees({
        name: 'withdrawalAddedFee',
        componentProps: {
          labelText: _t('withdrawal_added_fees') + ', %'
        },
        columnParams: {
          className: 'small-input__container-right',
          lg: 11,
          md: 5,
        }
      }),
      fields.formFields.validFrom({
        name: 'withdrawalAddedFeeValidFrom',
        columnParams: {
          lg: 5,
        },
      }),
      fields.formFields.withdrawalFees({
        name: 'withdrawalFixedFee',
        componentProps: {
          labelText: _t('withdrawal_transactional_fees')
        },
        columnParams: {
          className: 'small-input__container-right',
          lg: 7,
          md: 3,
        }
      }),
      fields.formFields.selectCurrenciesWithSearch({
        name: 'withdrawalFixedFeeCurrency',
        componentProps: {
          options: currenciesOptions
        },
        columnParams: {
          lg: 4,
          md: 2,
          className: 'max-width-input small-input__container-right'
        }
      }),
      fields.formFields.validFrom({
        name: 'withdrawalFixedFeeValidFrom',
        columnParams: {
          lg: 5,
        },
      }),
      fields.general.divider(),
      {
        component: RadioButton,
        name: "settlementFeeType",
        componentProps: (form: any) => {
          return {
            labelA: _t("fixed_fee"),
            labelB: _t('percentage'),
            labelText: "Settlement Fees type: ",
            onValueChange: (value: any) => setIsSettlementFeeTypeFixed(value === _t("fixed_fee"))
          }
        },
        columnParams: {
          lg: 12,
          md: 8,
          className: 'small-input__container-right'
        }
      },
      fields.formFields.settlementsFees({
        componentProps: {
          labelText: isSettlementFeeTypeFixed ? `${_t("settlement_fees")}, fixed` : `${_t("settlement_fees")}, %`,
        },
        columnParams: {
          className: 'small-input__container-right',
          lg: isSettlementFeeTypeFixed ? 7 : 11,
          md: isSettlementFeeTypeFixed ? 3 : 5,
        }
      }),
      fields.formFields.selectCurrenciesWithSearch({
        name: 'settlementFeeCurrency',
        componentProps: {
          options: currenciesOptions
        },
        columnParams: {
          lg: 4,
          md: 2,
          className: isSettlementFeeTypeFixed ? 'max-width-input small-input__container-right' : 'conditional-input'
        }
      }),
      fields.formFields.validFrom({
        name: 'settlementFeeValidFrom',
        columnParams: {
          lg: 5,
        },
      }),
      fields.general.divider(),
      fields.general.saveBtn(),
    ];
  }, [
    paymentGatewaysOptionsByMerchant,
    paymentGatewaysOptionsAll,
    merchantsOptions,
    currenciesOptions,
    isSettlementFeeTypeFixed,
    isAllGatewaysDisplay
  ]);
  return formConfig;
};
